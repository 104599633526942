export const isTouchSupportDevice = (): boolean => {
  return (
    'ontouchstart' in window ||
    (Boolean(
      (window as Window & typeof globalThis & { DocumentTouch: unknown })
        .DocumentTouch
    ) &&
      document instanceof Document) ||
    window.matchMedia('(pointer: coarse)').matches
  );
};

export const isAndroidPlatformDevice = (): boolean => {
  const platform =
    (navigator as Navigator & { userAgentData?: { platform: string } })
      .userAgentData?.platform || '';
  return platform.includes('Linux') || platform.includes('Android');
};
