import { LOCAL_STORAGE_KEY } from '@/utils/constants';
import { jwtDecode } from 'jwt-decode';

export const setRedirectPath = (path: string) => {
  cleanupRedirectPath();
  if (!path) {
    return;
  }
  localStorage.setItem(LOCAL_STORAGE_KEY.REDIRECT_PATH, path);
};

export const cleanupRedirectPath = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.REDIRECT_PATH);
};

export const getRedirectPath = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.REDIRECT_PATH);
};

export const setRestrictedUserEmail = (email: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.RESTRICTED_USER_EMAIL, email);
};

export const getRestrictedUserEmail = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.RESTRICTED_USER_EMAIL);
};

export const cleanupRestrictedUserEmail = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.RESTRICTED_USER_EMAIL);
};

export const saveUserTokenToLocalStorage = (token: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.USER_TOKEN, token);
};

export const removeUserTokenFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.USER_TOKEN);
};

export const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY.USER_TOKEN);
  if (!token) return;

  const decodedToken = jwtDecode(token);
  if (!decodedToken.exp) {
    removeUserTokenFromLocalStorage();
    return;
  }

  const tokenExpirationTime = decodedToken.exp * 1000;

  // Token is considered expired 5 minutes before its actual expiration time
  const isTokenExpired =
    new Date(tokenExpirationTime - 5 * 60 * 1000) < new Date();
  if (isTokenExpired) {
    removeUserTokenFromLocalStorage();
    return;
  }

  return token;
};
