import { useUserStore } from '@/store/user';
import { MESSAGE_DURATION } from '@/utils/constants';
import { ElMessage } from 'element-plus';
import { eAPI_METHOD, eHTTP_RESPONSE_STATUS } from '@/types/enums';

interface ApiRequestOptions {
  method: eAPI_METHOD;
  body?: object;
  query?: Record<
    string,
    | string
    | number
    | boolean
    | undefined
    | Array<string | number | boolean | undefined>
  >;
  signal?: AbortSignal;
  customOptions?: {
    handleErrorMessage?: {
      showErrorMessage?: boolean;
      errorMessageText?: string;
    };
    handleLocalError?: boolean;
    cacheResponse?: boolean;
    key?: string;
    isStreaming?: boolean;
    handleStreamingResponse?: (response: Response) => void;
  };
}

export const apiFetch = async <T>(url: string, params: ApiRequestOptions) => {
  const config = useRuntimeConfig();
  const userStore = useUserStore();
  const nuxtApp = useNuxtApp();

  const {
    customOptions = {
      handleErrorMessage: {
        showErrorMessage: false,
        errorMessageText: 'Something went wrong. Please try again later.',
      },
      handleLocalError: false,
      isStreaming: false,
      handleStreamingResponse: null,
    },
    ...options
  } = params || {};

  if (userStore.token && userStore.isTokenExpired()) {
    try {
      const token = await nuxtApp.$auth0.getTokenSilently();
      userStore.saveTokenData(token);
    } catch (_) {
      await nuxtApp.$auth0.loginWithRedirect();
      return;
    }
  }

  return $fetch<T>(url, {
    baseURL: `${config.public.apiBase}api/${config.public.apiVersion}/`,
    ...options,
    headers: {
      Accept: 'application/json',
      ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    },
    responseType: customOptions.isStreaming
      ? ('stream' as const)
      : ('json' as const),
    async onResponseError({ response }) {
      if (!customOptions?.handleLocalError) {
        if (
          customOptions?.handleErrorMessage?.showErrorMessage &&
          response.status !== eHTTP_RESPONSE_STATUS.UNAUTHORIZED
        ) {
          ElMessage.error({
            message: customOptions.handleErrorMessage.errorMessageText,
            duration: MESSAGE_DURATION,
          });
        }

        if (
          response.status === eHTTP_RESPONSE_STATUS.INTERNAL_SERVER_ERROR ||
          response.status === eHTTP_RESPONSE_STATUS.NOT_FOUND
        ) {
          showError({
            statusCode: response.status,
            statusMessage: response.statusText,
          });
        }

        if (response.status === eHTTP_RESPONSE_STATUS.UNAUTHORIZED) {
          window.location.reload();
        }
      }
    },

    async onResponse({ response }: { response: Response }) {
      if (
        customOptions?.isStreaming &&
        customOptions?.handleStreamingResponse
      ) {
        customOptions.handleStreamingResponse(response);
      }
    },
  });
};

export const apiHandleStreamingResponse = (
  startStopCallback: (isStarted: boolean, id?: string) => void,
  chunkReceivedCallback: (chunk: string, id?: string) => void,
  id?: string
) => {
  return async (response: Response) => {
    const reader = response.body?.getReader();
    const decoder = new TextDecoder('utf-8');
    let value;
    let done = false;
    startStopCallback(true);

    while (!done) {
      try {
        if (reader) {
          const { value: chunkValue, done: doneReading } = await reader.read();
          value = chunkValue;
          done = doneReading;
        }
      } catch (_) {
        done = true;
      }

      if (done) {
        startStopCallback(false, id);
        break;
      }

      const chunk = decoder.decode(value);
      try {
        const parsedChunk = JSON.parse(chunk);
        if (!parsedChunk?.detail) {
          chunkReceivedCallback?.(chunk, id);
        }
      } catch (_) {
        chunkReceivedCallback?.(chunk, id);
      }
    }
  };
};

export const isCanceledApiRequest = (err: unknown) => {
  return (
    (err as { name?: string })?.name === 'AbortError' ||
    (err as { message?: string })?.message?.toLowerCase().includes('abort')
  );
};
