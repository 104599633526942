import { removeAllExitFromReaderLocations } from '@/utils/localStorage';
import { isTouchSupportDevice } from '@/utils/devices';

export default defineNuxtPlugin(() => {
  // Run this code when the application starts
  const isTouchDevice = isTouchSupportDevice();

  if (!isTouchDevice) {
    // Remove all exit from reader locations
    removeAllExitFromReaderLocations();
  }
});
