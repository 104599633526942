import { apiFetch } from '@/utils/api';
import type { iUser } from '@/types/apiModels';
import { eAPI_METHOD } from '@/types/enums';

// Get user's data
let apiGetUserController: AbortController;
export const apiGetUser = async () => {
  apiGetUserController?.abort?.();
  apiGetUserController = new AbortController();

  const res = await apiFetch<iUser>('users/me', {
    method: eAPI_METHOD.GET,
    signal: apiGetUserController.signal,
    customOptions: {
      handleLocalError: true,
    },
  });

  return res;
};

// Update user's data
export const apiUpdateUser = async (body: Partial<iUser>) => {
  const res = await apiFetch<iUser>('users/me', {
    method: eAPI_METHOD.PATCH,
    body,
  });

  return res;
};

// Get user token to use with trial signup
export const apiGetUserToken = async (email: string) => {
  const res = await apiFetch<string>('users/create-trial-profile', {
    method: eAPI_METHOD.POST,
    body: { email },
  });

  return res;
};
