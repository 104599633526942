import { apiFetch } from '@/utils/api';
import { eAPI_METHOD, eANALYTIC_SERVICE } from '@/types/enums';

/**
 * Function analytic track
 * @param {eANALYTIC_SERVICE.UMAMI | eANALYTIC_SERVICE.API} service - service name. Possible values - umami, api
 * @param {string} event_name - event name
 * @param {object} data - data to analytics send
 */
export async function sendAnatylics(
  service: eANALYTIC_SERVICE,
  event_name: string,
  data: object
) {
  switch (service) {
    case ANALYTIC_SERVICE.UMAMI:
      // TODO !!! Move umami servrice to plugin instead of global scope variable from external script !!!
      // @ts-expect-error - umami is global variable
      if (typeof umami !== 'undefined') {
        // @ts-expect-error - umami is global variable
        umami.track(event_name, data);
      }
      break;
    case ANALYTIC_SERVICE.API:
      apiFetch('analytics', {
        method: eAPI_METHOD.POST,
        body: {
          event_type: event_name,
          data,
        },
      });
      break;
    default:
      break;
  }
}
