export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  if (document.getElementById('umami-analytics-script')) return;

  const fjs = document.getElementsByTagName('script')[0];
  const js = document.createElement('script');
  js.id = 'umami-analytics-script';
  js.src = `https://us.umami.is/script.js?data-website-id=${config.public.umamiId}`;
  js.async = true;
  js.setAttribute('data-website-id', config.public.umamiId);

  fjs.parentNode?.insertBefore(js, fjs);
});
