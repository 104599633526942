import { useFeatureFlagStore } from '@/store/feature-flag';

export default defineNuxtPlugin({
  parallel: true,
  async setup() {
    const store = useFeatureFlagStore();
    await store.initStore();
    store.setDefaultHighlightColors();
  },
});
