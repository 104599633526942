import type { Nullable } from '@/types/utility';

export const updateReaderColorTheme = (className?: string) => {
  if (!className) {
    return;
  }
  const html = document.documentElement;
  html.removeAttribute('class');
  html.classList.add(className);
};

export const isClickInside = (
  eventTarget: Nullable<Element>,
  selector: string
) => {
  if (!eventTarget || !eventTarget.matches || !selector) {
    return false;
  }

  if (eventTarget.matches(selector)) {
    return true;
  }

  if (eventTarget.closest(selector)) {
    return true;
  }

  return false;
};

export const updateRootCssReaderFont = (fontFamily?: string) => {
  if (!fontFamily) {
    return;
  }
  const root = document.querySelector(':root') as HTMLElement;
  root?.style.setProperty('--reader-selected-font', fontFamily);
};

export const getElementChatTextarea = () => {
  return document.getElementById('chat-textarea');
};

export const getElementChatBody = () => {
  return document.getElementById('chat-body');
};

export const getBookmarkTextContextMenuElement = () =>
  document.getElementById('bookmark-text-context-menu');

export const addSelectionInProgressClass = (
  e:
    | HTMLBodyElementEventMap['mousedown']
    | HTMLBodyElementEventMap['touchstart']
) => {
  const isAndroidDevice = isAndroidPlatformDevice();
  if (isAndroidDevice) return;
  const body = document.body;
  if (isClickInside(e.target as HTMLElement, '.highlight-action-bar')) return;
  body?.classList.add('selection-in-progress');
};

export const removeSelectionInProgressClass = (
  e: HTMLBodyElementEventMap['mouseup'] | HTMLBodyElementEventMap['touchend']
) => {
  const body = document.body;
  const isAndroidDevice = isAndroidPlatformDevice();
  if (isAndroidDevice) return;
  if (isClickInside(e.target as HTMLElement, '.highlight-action-bar')) return;
  body?.classList.remove('selection-in-progress');
};
