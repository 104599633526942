import payload_plugin_qLmFnukI99 from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/e-reader-fe/e-reader-fe/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/runner/work/e-reader-fe/e-reader-fe/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import components_client_ZB1W0EeH0E from "/home/runner/work/e-reader-fe/e-reader-fe/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import auth0_ZnwwTmFbx2 from "/home/runner/work/e-reader-fe/e-reader-fe/plugins/auth0.ts";
import dom_purify_html_sOkrqWAOfC from "/home/runner/work/e-reader-fe/e-reader-fe/plugins/dom-purify-html.ts";
import featureFlag_client_nTetbxQGjX from "/home/runner/work/e-reader-fe/e-reader-fe/plugins/featureFlag.client.ts";
import gtm_cXsUZobmSF from "/home/runner/work/e-reader-fe/e-reader-fe/plugins/gtm.ts";
import init_grdyAU8lhf from "/home/runner/work/e-reader-fe/e-reader-fe/plugins/init.ts";
import lazyLoad_VLTc6f4YEq from "/home/runner/work/e-reader-fe/e-reader-fe/plugins/lazyLoad.ts";
import noscript_IkT0dHAwiG from "/home/runner/work/e-reader-fe/e-reader-fe/plugins/noscript.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/e-reader-fe/e-reader-fe/plugins/sentry.client.ts";
import umami_client_8GAmfEmPme from "/home/runner/work/e-reader-fe/e-reader-fe/plugins/umami.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  components_client_ZB1W0EeH0E,
  auth0_ZnwwTmFbx2,
  dom_purify_html_sOkrqWAOfC,
  featureFlag_client_nTetbxQGjX,
  gtm_cXsUZobmSF,
  init_grdyAU8lhf,
  lazyLoad_VLTc6f4YEq,
  noscript_IkT0dHAwiG,
  sentry_client_shVUlIjFLk,
  umami_client_8GAmfEmPme
]