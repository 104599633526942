/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  (function (w: Window, d: Document, s: string, l: string, i: string) {
    (w as any)[l] = (w as any)[l] || [];
    (w as any)[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    (j as HTMLScriptElement).async = true;
    (j as HTMLScriptElement).src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode?.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', config.public.gtm);
});
