<template>
  <el-header class="header">
    <el-container>
      <el-row class="header_row">
        <el-col :xs="4" :sm="2" :md="0">
          <el-dropdown
            trigger="click"
            popper-class="navbar__menu"
            placement="bottom-start"
            class="header_dropdown"
            @visible-change="dropdownChange"
          >
            <button class="dropdown-btn">
              <close-icon v-if="opened" class="close" />
              <burger-icon v-else />
            </button>
            <template #dropdown>
              <el-dropdown-menu
                style="width: 253px"
                class="header_dropdown-menu"
              >
                <el-dropdown-item
                  class="header_dropdown-link"
                  @click="catalogueRedirect"
                  >Catalogue</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="userStore.isAuthenticated"
                  class="header_dropdown-link"
                  @click="myBooksRedirect"
                  >My Books</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
        <el-col :xs="11" :sm="6">
          <MainLogo class="header_logo">
            <div class="header_logo_svg">
              <LogoIcon />
            </div>
          </MainLogo>
        </el-col>
        <el-col :xs="0" :md="12" class="header_navWrap">
          <nav class="header_nav">
            <ul v-if="hasNav">
              <li>
                <span class="header_link" @click="catalogueRedirect"
                  >Catalogue</span
                >
              </li>
              <li v-if="userStore.isAuthenticated">
                <span class="header_link" @click="myBooksRedirect"
                  >My Books</span
                >
              </li>
            </ul>
          </nav>
        </el-col>
        <el-col
          v-if="!userStore.isAuthenticated"
          class="header_actions"
          :xs="9"
          :sm="16"
          :md="6"
        >
          <template v-if="hasAuth">
            <AppButton
              class="header_login"
              variant="text"
              size="sm"
              @click="login"
            >
              Log In
            </AppButton>
            <AppButton
              v-if="storeFeatureFlag.isSignUpAvailable"
              class="header_signup"
              size="sm"
              @click="signUp"
            >
              Sign Up
            </AppButton>
          </template>
        </el-col>
        <el-col v-else class="header_actions" :xs="9" :sm="16" :md="6">
          <NuxtLink class="header_link" to="/account">Account</NuxtLink>
        </el-col>
      </el-row>
    </el-container>
  </el-header>
</template>

<script setup>
import BurgerIcon from '@/components/ui/icons/BurgerIcon.vue';
import AppButton from '@/components/ui/button/AppButton.vue';
import MainLogo from '@/components/layout/MainLogo.vue';
import LogoIcon from '@/components/ui/icons/LogoIcon.vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import { setRedirectPath } from '@/utils/auth';
import { useFeatureFlagStore } from '@/store/feature-flag';
import { useUserStore } from '@/store/user';
import { sendAnatylics } from '@/utils/analytics';
import { ANALYTIC_SERVICE } from '@/utils/constants';

const { $auth0 } = useNuxtApp();
const storeFeatureFlag = useFeatureFlagStore();
const userStore = useUserStore();

defineProps({
  /**
   * show the navbar or not
   * @type {boolean}
   */
  hasNav: {
    type: Boolean,
    default: true,
  },
  /**
   * show auth links or not
   * @type {boolean}
   */
  hasAuth: {
    type: Boolean,
    default: true,
  },
});

const opened = ref(false);

const router = useRouter();
const route = useRoute();

/**
 * Redirect to catalogue
 */
const catalogueRedirect = () => {
  opened.value = false;

  if (!userStore.isAuthenticated) {
    sendAnatylics(ANALYTIC_SERVICE.UMAMI, 'login', {
      user_id: 'not authorised',
      redirect: '/#catalogue',
    });
    setRedirectPath('/#catalogue');
    $auth0.loginWithRedirect();
  } else {
    router.push({ path: '/' });
  }
};

/**
 * Redirect to my books page
 */
const myBooksRedirect = () => {
  opened.value = false;
  router.push({ path: '/my-books' });
};

/**
 * Visible dropdown menu
 * @param {boolean} visible - riggers when the dropdown appears/disappears
 */
const dropdownChange = (visible) => {
  opened.value = visible;
};

/**
 * Login app
 */
const login = async () => {
  setRedirectPath(route.fullPath);
  await sendAnatylics(ANALYTIC_SERVICE.UMAMI, 'login', {
    user_id: 'not authorised',
    redirect: route.fullPath,
  });
  $auth0.loginWithRedirect();
};

/**
 * SignUp app
 */
const signUp = async () => {
  setRedirectPath(route.fullPath);
  await sendAnatylics(ANALYTIC_SERVICE.UMAMI, 'signup', {
    user_id: 'not authorised',
  });
  $auth0.loginWithRedirect({
    authorizationParams: {
      screen_hint: 'signup',
    },
  });
};
</script>

<style lang="scss">
.dropdown-btn {
  .close {
    path {
      fill: var(--app-color-gray-100);
    }
  }
}
</style>

<style lang="scss" scoped>
.header {
  position: relative;
  z-index: 3;
  height: auto;
  padding: 30px 0 40px;

  &.static {
    background-color: #faf6f0;
  }

  &_row {
    align-items: center;
    width: 100%;
  }

  &_dropdown {
    display: none;

    .dropdown-btn {
      cursor: pointer;

      width: 48px;
      height: 48px;
      border: none;
      border-radius: 50%;

      .close {
        width: 24px;
        height: 24px;
      }
    }
  }

  .el-dropdown-menu {
    padding: 24px 0;
  }

  &_logo {
    display: flex;
    align-items: center;

    &_svg {
      max-width: 181px;
      max-height: 48px;
    }
  }

  &_nav {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_link {
    cursor: pointer;

    margin: 0 20px;

    font-family: $euclid;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: var(--app-color-gray-70);

    &:hover {
      color: var(--app-color-gray-100);
    }
  }

  &_actions {
    display: flex;
    justify-content: flex-end;

    .header {
      &_link {
        font-size: 14px;
      }
    }
  }

  &_dropdown-link {
    a {
      width: 100%;

      font-family: $euclid;
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      color: var(--app-color-gray-100);
    }
  }

  &_icon {
    width: 40px;
    height: 40px;
    padding: 0 !important;
    border-radius: 50% !important;

    svg {
      margin-top: 3px;
    }

    &.btn {
      & + .btn {
        margin-left: 20px !important;
      }
    }

    &.icon {
      &_twitter {
        background-color: var(--app-brand-color-yellow);
      }
    }
  }

  &_login {
    color: var(--app-color-gray-70) !important;

    &:hover {
      color: var(--app-color-white) !important;
    }
  }
}

@media (width <=991px) {
  .header {
    padding: 24px 0 20px;

    &_dropdown {
      display: block;
    }

    &_navWrap {
      display: none;
    }
  }
}

@media (width <=767px) {
  .header {
    padding: 20px 0;

    &_logo {
      h3 {
        margin-left: 16px;
      }
    }

    &_signup {
      display: none !important;
    }
  }
}
</style>
