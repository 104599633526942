import { eHIGHLIGHT_COLOR } from '@/types/enums';

export const HIGHLIGHTED_DEFAULT_TEXT_COLOR = '#2c67bf80';
export const HIGHLIGHT_DEFAULT_COLOR_1 = 'hsl(201 98% 32%)';
export const HIGHLIGHT_DEFAULT_COLOR_2 = 'hsl(89 57% 30%)';
export const HIGHLIGHT_DEFAULT_COLOR_3 = 'hsl(39 96% 40%)';
export const HIGHLIGHT_DEFAULT_COLOR_4 = 'hsl(346 66% 59%)';

export const MEDIA_QUERY_BREAKPOINT = {
  smallMobile: 575,
  mobile: 767.98,
  largeMobile: 842,
  tablet: 991.98,
  smallDesktop: 1200,
};

export const BOOKMARK_TYPE = {
  LOVE: 'love',
  DISLIKE: 'dislike',
  INTERROGATIVE: 'interrogative',
  VIDEO: 'video',
  ANNOTATION: 'annotation',
};

// export const DEFAULT_CACHE_TIME_MS = 1000 * 60 * 10; // 10 minutes
export const DEFAULT_CACHE_TIME_MS = 1000 * 60 * 5; // 5 minutes
// export const DEFAULT_CACHE_TIME_MS = 1000 * 10; // 10 seconds

export const BOOKMARKS_PER_PAGE = 8;

export const WORDS_DEFAULT_LIMIT = 10000;

export const LOCAL_STORAGE_KEY = {
  REDIRECT_PATH: 'redirectPath',
  RESTRICTED_USER_EMAIL: 'restrictedUserEmail',
  USER_TOKEN: 'user_token',
};

export const POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
  RIGHT: 'right',
  LEFT: 'left',
};

export const DEFAULT_HEIGHT_TEXTAREA = 52;

export const FONT_TYPES = [
  'Palatino',
  'Arial',
  'Verdana',
  'Times New Roman',
  'Comic Sans',
  'OpenDyslexic',
];

export const READER_SETTINGS_LAYOUT_OPTIONS = {
  SINGLE_PAGE: 'Single Page',
  TWO_PAGES: 'Two Pages',
};

export const READER_SETTINGS_NAVIGATION_OPTIONS = {
  PAGINATED: 'Paginated',
  CONTINUOUS: 'Continuous',
};

export const CONVERSATION_TYPES = {
  WITH_ASSISTANT_DEFAULT: 'researcher',
  ABOUT_TUTORIAL: 'waypoint',
  WITH_REBINDER_DEFAULT: 'user_initiated_guided',
  WITH_REBINDER_ABOUT_BOOKMARK: 'single_bookmark_rebinder',
  WITH_ASSISTANT_ABOUT_BOOKMARK: 'single_bookmark_utility',
  WITH_REBINDER_PRIMARY: 'nearby_primary_rebinder',
};

export const GENERATED_QUESTION = {
  click: 'Click',
  type: 'Type',
};

export const CHAT_INITIAL_MESSAGE = 'What can I help you with?';
export const THOUGHTSPOTS_CONVERSATION_DEFAULT_TITLE =
  "Let's take a deeper look at the highlighted passage. Where should we start?";

export const TIME_IDLE = 5000;
export const MESSAGE_DURATION = 5000;

export const NOTIFICATION_TEXT = {
  CHAT_ERROR_MESSAGE: 'Conversation seems unavailable right now',
  DISCUSSION_UNAVAILABLE: 'Discussions seem unavailable right now',
};

export const TOOLBAR_MENU_TYPE = {
  TABLE_OF_CONTENTS: 'tableOfContents',
  SEARCH: 'search',
  SETTINGS: 'settings',
  BOOKMARKS: 'bookmarks',
  SHARE: 'share',
};

export const CONVERSATION_ORIGIN = {
  CHAT_NOW_REBINDER: 'rebinder_chat_now',
  CHAT_NOW_ASSISTANT: 'assistant_chat_now',
  BOOKMARK_MENU_REBINDER: 'discuss_menu_rebinder',
  BOOKMARK_MENU_ASSISTANT: 'discuss_menu_assistant',
  THREADS_REBINDER_TAB: 'threads_rebinder_tab',
  THREADS_ASSISTANT_TAB: 'threads_assistant_tab',
  NEW_CONVERSATION: 'conversations_new',
  TOC_DISCUSSION: 'toc_discussion',
  BOOK_CONTENT_DISCUSSION: 'start_discussion',
};

export const CHAT_EVENT_TRIGGER = {
  CLICK_PREVIOUS_PAGE: 'click_previous_page',
  CLICK_NEXT_PAGE: 'click_next_page',
};

export const CHAT_EVENTS = {
  [CHAT_EVENT_TRIGGER.CLICK_PREVIOUS_PAGE]: {
    [CONVERSATION_TYPES.WITH_REBINDER_DEFAULT]: 'previous_page_click_guided',
    [CONVERSATION_TYPES.WITH_ASSISTANT_DEFAULT]: 'previous_page_click_research',
    [CONVERSATION_TYPES.WITH_REBINDER_ABOUT_BOOKMARK]:
      'previous_page_click_rebinder',
    [CONVERSATION_TYPES.WITH_ASSISTANT_ABOUT_BOOKMARK]:
      'previous_page_click_utility',
    [CONVERSATION_TYPES.ABOUT_TUTORIAL]: 'previous_page_click_waypoint',
    [CONVERSATION_TYPES.WITH_REBINDER_PRIMARY]: 'previous_page_click_nearby',
  },
  [CHAT_EVENT_TRIGGER.CLICK_NEXT_PAGE]: {
    [CONVERSATION_TYPES.WITH_REBINDER_DEFAULT]: 'next_page_click_guided',
    [CONVERSATION_TYPES.WITH_ASSISTANT_DEFAULT]: 'next_page_click_research',
    [CONVERSATION_TYPES.WITH_REBINDER_ABOUT_BOOKMARK]:
      'next_page_click_rebinder',
    [CONVERSATION_TYPES.WITH_ASSISTANT_ABOUT_BOOKMARK]:
      'next_page_click_utility',
    [CONVERSATION_TYPES.ABOUT_TUTORIAL]: 'next_page_click_waypoint',
    [CONVERSATION_TYPES.WITH_REBINDER_PRIMARY]: 'next_page_click_nearby',
  },
};

export const ANALYTIC_SERVICE = {
  UMAMI: 'umami',
  API: 'api',
};

export const ANALYTIC_EVENT_NAME = {
  BOOK_OPEN: 'book_open',
  FIRST_CHAPTER_COMPLETE: 'first_chapter_complete',
  REFLECT_AND_WRITE_CTA_BUTTON_CLICK: 'reflect_and_write_cta_button_click',
  REFLECT_AND_WRITE_REFRESH_PROMPT_CLICK:
    'reflect_and_write_refresh_prompt_click',
  REFLECT_AND_WRITE_ANNOTATION_ADDED: 'reflect_and_write_annotation_added',
  REFLECT_AND_WRITE_ANNOTATION_EDITED: 'reflect_and_write_annotation_edited',
  ANNOTATION_REFRESH_PROMPT_CLICK: 'annotation_refresh_prompt_click',
  ANNOTATION_GENERATE_PROMPT_CLICK: 'annotation_generate_prompt_click',
  ARTICLE_BANNER_VISIBLE: 'article_banner_visible',
  ARTICLE_BANNER_CLICKED: 'article_banner_clicked',
  ARTICLE_DRAWER_CLOSED: 'article_drawer_closed',
  ARTICLE_READ_COMPLETED: 'article_read_completed',
};

export const ANNOTATION_MENU_VIEW_MODE = {
  VIEW: 'view',
  EDIT: 'edit',
};

export const RATE_MESSAGE_REACTION_TYPE = {
  LIKE: 'thumb_up',
  DISLIKE: 'thumb_down',
};

export const THOUGHTSPOT_TYPE = {
  REFLECTION: 'reflection',
  UNKNOWN: 'unknown',
};

export const BOOK_SETTINGS = {
  REFLECTION_PROMPT: {
    PURPOSE: {
      ACADEMIC: 'academic',
      PERSONAL: 'personal',
    },
    LEVEL: {
      NOVICE: 'novice',
      INTERMEDIATE: 'intermediate',
      ADVANCED: 'advanced',
      EXPERT: 'expert',
    },
  },
};

export const BOOKMARK_LOCATION_TYPE = {
  HIGHLIGHT: 'highlight',
  VIDEO: 'video',
  DISCUSSION: 'discussion',
  REFLECTION: 'reflection',
  ARTICLE: 'article',
};

export const BOOK_VIEW_MODE = {
  ORIGINAL: 'original',
  CONTEMPORARY: 'contemporary',
};

export const ARTICLE_PLACEHOLDER_TYPE = {
  PULL_QUOTE_PRIMARY: 'pull_quote_primary',
  PULL_QUOTE_SECONDARY: 'pull_quote_secondary',
};

export const BOOKMARK_COLORS = [
  eHIGHLIGHT_COLOR.COLOR_1,
  eHIGHLIGHT_COLOR.COLOR_2,
  eHIGHLIGHT_COLOR.COLOR_3,
  eHIGHLIGHT_COLOR.COLOR_4,
];
