import { defineStore } from 'pinia';
import { jwtDecode } from 'jwt-decode';
import { apiGetUser, apiUpdateUser } from '@/api/users';
import { updateRootCssReaderFont } from '@/utils/dom';
import type { iUser } from '@/types/apiModels';
import type { Nullable } from '@/types/utility';

export const useUserStore = defineStore('user', () => {
  // state
  const user = ref<Nullable<iUser>>(null);
  const token = ref<Nullable<string>>(null);
  const tokenExpirationTime = ref<Nullable<number>>(null);
  const isReady = ref(false);

  // actions
  async function getUser() {
    const data = await apiGetUser();

    user.value = data || null;
    isReady.value = true;

    updateRootCssReaderFont(data?.reader_settings.font);
    return data;
  }

  function saveTokenData(newToken: string) {
    if (!newToken) return;
    const decodedToken = jwtDecode(newToken);
    token.value = newToken;
    if (decodedToken.exp) {
      tokenExpirationTime.value = decodedToken.exp * 1000;
    }
  }

  function cleanupUserData() {
    user.value = null;
    token.value = null;
    tokenExpirationTime.value = null;
  }

  async function updateUser(payload: Partial<iUser>) {
    const data = await apiUpdateUser(payload);
    if (data) {
      user.value = data;
    }
  }

  function isTokenExpired() {
    if (!tokenExpirationTime.value) return true;
    // Token is considered expired 5 minutes before its actual expiration time
    return new Date(tokenExpirationTime.value - 5 * 60 * 1000) < new Date();
  }

  function updateReaderSettings(settings: Partial<iUser['reader_settings']>) {
    if (!user.value?.reader_settings) return;

    user.value = {
      ...user.value,
      reader_settings: {
        ...user.value.reader_settings,
        ...settings,
      },
    };

    updateUser({
      reader_settings: user.value.reader_settings,
    });
  }

  // getters
  const isAuthenticated = computed(() => Boolean(user.value));
  const readerSettings = computed(() => user.value?.reader_settings);
  const readerSettingsSelectedTheme = computed(
    () => user.value?.reader_settings?.theme
  );
  const readerSettingsSelectedFont = computed(
    () => user.value?.reader_settings?.font
  );
  const readerSettingsSelectedFontSize = computed(
    () => user.value?.reader_settings?.font_size
  );
  const readerSettingsSelectedPageLayout = computed(
    () => user.value?.reader_settings?.page_layout
  );
  const readerSettingsSelectedNavigation = computed(
    () => user.value?.reader_settings?.navigation
  );
  const isSubscriptionExpired = computed(
    () => !user.value?.is_subscribed && user.value?.subscription_expires_at
  );
  const hasActiveOrExpiredSubscription = computed(
    () => user.value?.is_subscribed || user.value?.subscription_expires_at
  );
  const isSubscriptionActive = computed(() => user.value?.is_subscribed);
  const isTrialSubscription = computed(() => user.value?.is_trial_subscription);

  // utils
  function $reset() {
    user.value = null;
    token.value = null;
    tokenExpirationTime.value = null;
    isReady.value = false;
  }

  return {
    user: readonly(user),
    token: readonly(token),
    tokenExpirationTime: readonly(tokenExpirationTime),
    isReady: readonly(isReady),
    getUser,
    saveTokenData,
    cleanupUserData,
    updateUser,
    isTokenExpired,
    updateReaderSettings,
    isAuthenticated,
    readerSettings,
    readerSettingsSelectedTheme,
    readerSettingsSelectedFont,
    readerSettingsSelectedFontSize,
    readerSettingsSelectedPageLayout,
    readerSettingsSelectedNavigation,
    isSubscriptionExpired,
    hasActiveOrExpiredSubscription,
    isSubscriptionActive,
    isTrialSubscription,
    $reset,
  };
});
