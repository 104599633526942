export enum eTHOUGHTSPOT_REACTION_TYPE {
  LOVE = 'love',
  DISLIKE = 'dislike',
  INTERROGATIVE = 'interrogative',
}

export enum eTHOUGHTSPOT_LOCATION_TYPE {
  HIGHLIGHT = 'highlight',
  VIDEO = 'video',
  DISCUSSION = 'discussion',
  REFLECTION = 'reflection',
  ARTICLE = 'article',
}

export enum eBOOK_VIEW_MODE {
  ORIGINAL = 'original',
  CONTEMPORARY = 'contemporary',
}

export enum eREADER_SETTINGS_FONT_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum eREADER_SETTINGS_COLOR_THEME {
  LIGHT = 'light',
  DARK = 'dark',
  SEPIA = 'sepia',
}

export enum eREADER_SETTINGS_FONT {
  PALATINO = 'Palatino',
  ARIAL = 'Arial',
  VERDANA = 'Verdana',
  TIMES_NEW_ROMAN = 'Times New Roman',
  COMIC_SANS = 'Comic Sans',
  OPEN_DYSLEXIC = 'OpenDyslexic',
}

export enum eREADER_SETTINGS_PAGE_LAYOUT {
  SINGLE_PAGE = 'Single Page',
  TWO_PAGES = 'Two Pages',
}

export enum eREADER_SETTINGS_NAVIGATION {
  PAGINATED = 'Paginated',
  CONTINUOUS = 'Continuous',
}
export enum eANNOTATION_MENU_VIEW_MODE {
  VIEW = 'view',
  EDIT = 'edit',
}
export enum eCONVERSATION_TYPE {
  WITH_ASSISTANT_DEFAULT = 'researcher',
  ABOUT_TUTORIAL = 'waypoint',
  WITH_REBINDER_DEFAULT = 'user_initiated_guided',
  WITH_REBINDER_ABOUT_BOOKMARK = 'single_bookmark_rebinder',
  WITH_ASSISTANT_ABOUT_BOOKMARK = 'single_bookmark_utility',
  WITH_REBINDER_PRIMARY = 'nearby_primary_rebinder',
}
export enum eCONVERSATION_ORIGIN {
  CHAT_NOW_REBINDER = 'rebinder_chat_now',
  CHAT_NOW_ASSISTANT = 'assistant_chat_now',
  BOOKMARK_MENU_REBINDER = 'discuss_menu_rebinder',
  BOOKMARK_MENU_ASSISTANT = 'discuss_menu_assistant',
  THREADS_REBINDER_TAB = 'threads_rebinder_tab',
  THREADS_ASSISTANT_TAB = 'threads_assistant_tab',
  NEW_CONVERSATION = 'conversations_new',
  TOC_DISCUSSION = 'toc_discussion',
  BOOK_CONTENT_DISCUSSION = 'start_discussion',
}

export enum eCHAT_MESSAGE_INPUT_TYPE {
  BY_PROMPT_SELECT = 'Click',
  BY_MANUAL_INPUT = 'Type',
}

export enum eRATE_MESSAGE_REACTION_TYPE {
  LIKE = 'thumb_up',
  DISLIKE = 'thumb_down',
}

export enum eTOOLBAR_MENU_TYPE {
  TABLE_OF_CONTENTS = 'tableOfContents',
  SEARCH = 'search',
  SETTINGS = 'settings',
  BOOKMARKS = 'bookmarks',
  SHARE = 'share',
}

export enum eARTICLE_PLACEHOLDER_TYPE {
  PULL_QUOTE_PRIMARY = 'pull_quote_primary',
  PULL_QUOTE_SECONDARY = 'pull_quote_secondary',
}

export enum eMEDIA_QUERY_BREAKPOINT {
  SMALL_MOBILE = 575,
  MOBILE = 767.98,
  LARGE_MOBILE = 842,
  TABLET = 991.98,
  SMALL_DESKTOP = 1200,
}

export enum eHIGHLIGHT_TYPE {
  LOVE = 'love',
  DISLIKE = 'dislike',
  INTERROGATIVE = 'interrogative',
  VIDEO = 'video',
  ANNOTATION = 'annotation',
  ARTICLE = 'article',
}

export enum eHIGHLIGHT_REACTION_TYPE {
  LOVE = 'love',
  DISLIKE = 'dislike',
  INTERROGATIVE = 'interrogative',
}

export const eINLINE_SVG = {
  [eHIGHLIGHT_TYPE.LOVE]: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20" x="20" y="20" fill="innerColor">
        <path
          d="M2.667 11.2c-0-1.933 0.777-3.785 2.156-5.139s3.245-2.097 5.178-2.061c2.29-0.012 4.475 0.959 6 2.667 1.525-1.708 3.71-2.679 6-2.667 1.933-0.036 3.798 0.707 5.178 2.061s2.156 3.206 2.156 5.139c0 7.141-8.505 12.533-13.333 16.8-4.817-4.303-13.333-9.653-13.333-16.8z"
        ></path>
      </svg>
    `,
  [eHIGHLIGHT_TYPE.DISLIKE]: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20" x="20" y="20" fill="innerColor">
        <path d="M28.761 12.045l-0.469-0.51c-0.048-0.055-0.086-0.1-0.105-0.119 0.029-0.064 0.077-0.146 0.115-0.209 0.163-0.283 0.335-0.574 0.469-0.883 0.345-0.829 0.23-1.64-0.325-2.35-0.172-0.228-0.373-0.437-0.574-0.647-0.095-0.1-0.095-0.173-0.057-0.273l0.067-0.182c0.057-0.164 0.115-0.328 0.163-0.501 0.287-0.966-0.010-1.822-0.833-2.396-0.345-0.246-0.747-0.455-1.158-0.61-0.985-0.383-2.115-0.592-3.445-0.647-2.239-0.1-4.086-0.055-5.827 0.146-1.196 0.136-2.23 0.401-3.158 0.82-0.918 0.41-1.79 0.674-2.679 0.811-0.201 0.027-0.402 0.055-0.612 0.082-0.22 0.028-0.45 0.055-0.68 0.091s-0.402 0.219-0.402 0.447l0.010 12.989c0 0.109 0.038 0.219 0.115 0.3s0.125 0.128 0.239 0.209c1.512 1.284 2.756 2.487 3.808 3.68 1.282 1.457 2.22 2.742 2.938 4.053 0.21 0.383 0.402 0.765 0.603 1.148 0.153 0.31 0.306 0.619 0.469 0.92 0.316 0.601 0.842 0.92 1.473 0.92 0.172 0 0.354-0.028 0.536-0.073 0.593-0.146 1.1-0.492 1.55-1.066 0.842-1.057 1.177-2.332 1.005-3.78-0.134-1.084-0.555-2.149-1.253-3.161-0.239-0.346-0.497-0.674-0.775-1.002-0.019-0.027-0.048-0.064-0.067-0.091 0.143 0.009 0.297 0.027 0.44 0.036 0.555 0.045 1.12 0.091 1.675 0.119 1.205 0.045 2.201 0.073 3.253-0.082 0.574-0.091 1.32-0.228 2.028-0.555 0.928-0.447 1.761-1.412 1.292-2.806l-0.057-0.182c-0.067-0.191-0.134-0.383-0.22-0.574-0.086-0.2-0.067-0.355 0.077-0.547 0.105-0.119 0.201-0.246 0.297-0.383l0.105-0.164c0.115-0.164 0.249-0.364 0.335-0.583 0.364-0.902 0.239-1.723-0.363-2.396z"></path>
        <path d="M6.245 3.674h-1.693c-1.043 0-1.885 0.802-1.885 1.794v11.422c0 0.993 0.842 1.804 1.885 1.804h1.693c1.043 0 1.885-0.811 1.885-1.804v-11.422c0-0.993-0.842-1.794-1.885-1.794z"></path>
      </svg>
    `,
  [eHIGHLIGHT_TYPE.INTERROGATIVE]: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" height="20" x="20" y="20" fill="innerColor">
        <path d="M16 29.333c-7.364 0-13.333-5.97-13.333-13.333s5.97-13.333 13.333-13.333c7.364 0 13.333 5.97 13.333 13.333-0.008 7.36-5.973 13.325-13.333 13.333zM14.667 22.667v2.667h2.667v-2.667h-2.667zM16 9.333c1.473 0 2.667 1.194 2.667 2.667 0.005 0.708-0.279 1.387-0.787 1.88l-1.653 1.68c-0.998 1.002-1.559 2.359-1.56 3.773v0.667h2.667c-0.104-1.433 0.475-2.832 1.56-3.773l1.2-1.227c0.797-0.794 1.244-1.875 1.24-3 0-2.946-2.388-5.333-5.333-5.333s-5.333 2.388-5.333 5.333h2.667c0-1.473 1.194-2.667 2.667-2.667z"></path>
      </svg>
    `,
  [eHIGHLIGHT_TYPE.ANNOTATION]: `
      <svg width="20" height="20" viewBox="0 0 24 24" fill="innerColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V21C20 21.3746 19.7907 21.7178 19.4576 21.8892C19.1245 22.0606 18.7236 22.0315 18.4188 21.8137L12 17.2289L5.58124 21.8137C5.27642 22.0315 4.87549 22.0606 4.54242 21.8892C4.20935 21.7178 4 21.3746 4 21V5C4 4.20435 4.31607 3.44129 4.87868 2.87868Z" fill="#2c67bf"/>
      </svg>
    `,
  MENU: `
      <svg class="video-menu-icon" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 5C3 4.44772 3.44772 4 4 4H16C16.5523 4 17 4.44772 17 5C17 5.55228 16.5523 6 16 6H4C3.44772 6 3 5.55228 3 5Z" fill="inherit"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2H1C0.447715 2 0 1.55228 0 1Z" fill="inherit"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.44772 0.447715 8 1 8H19C19.5523 8 20 8.44772 20 9C20 9.55228 19.5523 10 19 10H1C0.447715 10 0 9.55228 0 9Z" fill="inherit"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 13C3 12.4477 3.44772 12 4 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14H4C3.44772 14 3 13.5523 3 13Z" fill="inherit"/>
      </svg>
    `,
};

export enum eAPI_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum eANALYTIC_SERVICE {
  UMAMI = 'umami',
  API = 'api',
}
export enum eANALYTIC_EVENT_NAME {
  BOOK_OPEN = 'book_open',
  FIRST_CHAPTER_COMPLETE = 'first_chapter_complete',
  REFLECT_AND_WRITE_CTA_BUTTON_CLICK = 'reflect_and_write_cta_button_click',
  REFLECT_AND_WRITE_REFRESH_PROMPT_CLICK = 'reflect_and_write_refresh_prompt_click',
  REFLECT_AND_WRITE_ANNOTATION_ADDED = 'reflect_and_write_annotation_added',
  REFLECT_AND_WRITE_ANNOTATION_EDITED = 'reflect_and_write_annotation_edited',
  ANNOTATION_REFRESH_PROMPT_CLICK = 'annotation_refresh_prompt_click',
  ANNOTATION_GENERATE_PROMPT_CLICK = 'annotation_generate_prompt_click',
  ARTICLE_BANNER_VISIBLE = 'article_banner_visible',
  ARTICLE_BANNER_CLICKED = 'article_banner_clicked',
  ARTICLE_DRAWER_CLOSED = 'article_drawer_closed',
  ARTICLE_READ_COMPLETED = 'article_read_completed',
  ARTICLE_FIRST_OPEN = 'article_first_open',
  VIDEO_START = 'video_start',
  VIDEO_PAUSE = 'video_pause',
  VIDEO_STOP = 'video_stop',
  VIDEO_CLOSED = 'video_closed',
  CODA_VISIBLE = 'coda_visible',
  CODA_VISIBLE_FIRST_TIME = 'coda_visible_first_time',
  CODA_OPENED = 'coda_opened',
  CODA_OPENED_FIRST_TIME = 'coda_opened_first_time',
  CODA_REFLECT_PROMPT_CLICKED = 'coda_reflect_prompt_clicked',
  CODA_REFLECT_PROMPT_CLICKED_FIRST_TIME = 'coda_reflect_prompt_clicked_first_time',
  CODA_ARTICLE_CLICKED = 'coda_article_clicked',
  CODA_ARTICLE_CLICKED_FIRST_TIME = 'coda_article_clicked_first_time',
}

export enum ePOSITION {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export enum eHIGHLIGHT_MENU_TYPE {
  CONTEXT_MENU = 'contextMenu',
  ANNOTATION_MENU = 'annotationMenu',
}
export enum eHTTP_RESPONSE_STATUS {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  UNPROCESSABLE_CONTENT = 422,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
}

export enum eAPI_RESPONSE_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum eHIGHLIGHT_COLOR {
  COLOR_1 = 'highlight-color-1',
  COLOR_2 = 'highlight-color-2',
  COLOR_3 = 'highlight-color-3',
  COLOR_4 = 'highlight-color-4',
}

export enum eFEATURE_FLAG {
  SIGN_UP = 'is_signup_available',
  PURCHASE = 'is_purchase_available',
  HIGHLIGHT_COLOR = 'highlight_color',
  API_RESPONSE_CACHING = 'is_api_response_caching_enabled',
  CLOSED_BETA_MESSAGE_TITLE = 'closed_beta_message_title',
  CLOSED_BETA_MESSAGE_TEXT = 'closed_beta_message_text',
  CLOSED_BETA_LINK = 'closed_beta_link',
  IS_LAYOUT_MODE_SETTINGS_ENABLED = 'is_layout_mode_settings_enabled',
  CHAT_WITH_REBINDER_TOOLTIP_TEXT = 'chat_with_rebinder_tooltip_text',
  CHAT_WITH_ASSISTANT_TOOLTIP_TEXT = 'chat_with_assistant_tooltip_text',
  IS_FULL_SCREEN_MODE_ENABLED = 'is_full_screen_mode_enabled',
  IS_SUBSCRIPTION_ENABLED = 'is_subscription_enabled',
  IS_REFLECTION_MODE_ENABLED = 'enable_reflection_mode',
  IS_RATE_RESPONSE_ENABLED = 'enable_rate_response',
  IS_REFLECTION_SETTINGS_ENABLED = 'reflection_settings',
  IS_RAIA_XRAY_ENABLED = 'is_raia_xray_enabled',
  IS_RAIA_DIAGNOSTICS_ENABLED = 'is_raia_diagnostics_enabled',
  ENABLE_ARTICLES = 'enable_articles',
  ARTICLE_TAGS_INSERTION_ENABLED = 'article_tags_insertion_enabled',
  CODAS_ENABLED = 'codas_enabled',
  HIGHLIGHT_COLOR_1 = eHIGHLIGHT_COLOR.COLOR_1,
  HIGHLIGHT_COLOR_2 = eHIGHLIGHT_COLOR.COLOR_2,
  HIGHLIGHT_COLOR_3 = eHIGHLIGHT_COLOR.COLOR_3,
  HIGHLIGHT_COLOR_4 = eHIGHLIGHT_COLOR.COLOR_4,
}

export enum eTHOUGHTSPOT_TYPE {
  REFLECTION = 'reflection',
  UNKNOWN = 'unknown',
}

export enum eMULTI_AUTHOR_CHAT_MESSAGE_TYPE {
  MODERATOR_ANSWER = 'moderator_answer',
  MODERATOR_QUESTION = 'moderator_question',
  MODERATOR_INTRODUCTION = 'moderator_introduction',
  REBINDER_ANSWER = 'rebinder_answer',
  REBINDER_QUESTION = 'rebinder_question',
}
