import { useUserStore } from '@/store/user';
import { setRedirectPath, getTokenFromLocalStorage } from '@/utils/auth';
import { sendAnatylics } from '@/utils/analytics';
import { eANALYTIC_SERVICE } from '@/types/enums';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const {
    authRequired,
    skipAuth,
    getFreeAccess = false,
    successRedirectUrl,
    authPage = 'login',
  } = to.meta;

  if (skipAuth) {
    return;
  }

  const setAuthTokenAndGetUser = async (token: string) => {
    userStore.saveTokenData(token);
    await userStore.getUser();
  };

  const isRedirectFromTrialLinkPage = from.path === '/trial-signup';

  const userStore = useUserStore();
  const { $auth0 } = useNuxtApp();
  const isAuthenticated = await $auth0.isAuthenticated();
  const localStorageToken = getTokenFromLocalStorage();

  if (
    (!isAuthenticated && !localStorageToken) ||
    !userStore.isReady ||
    !userStore.token
  ) {
    try {
      if (localStorageToken) {
        setAuthTokenAndGetUser(localStorageToken);
      } else {
        const token = await $auth0.getTokenSilently();
        setAuthTokenAndGetUser(token);
      }

      if (getFreeAccess) {
        const url = to.fullPath.replace('/gutten-test', '');
        return navigateTo(url);
      } else if (successRedirectUrl) {
        return navigateTo(
          isRedirectFromTrialLinkPage ? '/?free-trial=true' : successRedirectUrl
        );
      } else {
        return;
      }
    } catch (_) {
      if (!authRequired) {
        return;
      }
      // If the page requires authentication, redirect to the login page and then to the requested page
      userStore.cleanupUserData();

      const url = getFreeAccess
        ? to.fullPath.replace('/gutten-test', '')
        : successRedirectUrl || to.fullPath;

      sendAnatylics(eANALYTIC_SERVICE.UMAMI, 'login', {
        user_id: 'not authorised',
        redirect: url,
      });

      setRedirectPath(
        isRedirectFromTrialLinkPage ? '/?free-trial=true' : (url as string)
      );

      return navigateTo(
        `/auth?auth_screen_hint=${authPage}${
          getFreeAccess ? '&auth_free_access=true' : ''
        }`,
        {
          redirectCode: 301,
        }
      );
    }
  }
});
