// Function to parse HSL color string
function parseHSL(hsl: string) {
  const result = hsl.match(/hsl\((\d+)\s+(\d+)%\s+(\d+)%\)/);
  return result
    ? {
        h: parseInt(result[1], 10),
        s: parseInt(result[2], 10),
        l: parseInt(result[3], 10),
      }
    : null;
}

// Function to compute the relative color
export function computeRelativeHSLColor(
  baseColor: string,
  colorDiff: Array<number>
) {
  const hsl = parseHSL(baseColor);
  if (!hsl) return null;

  const [hAdjust, sAdjust, lAdjust, opacity] = colorDiff;

  const newH = (hsl.h + hAdjust) % 360;
  const newS = Math.min(Math.max(hsl.s + sAdjust, 0), 100);
  const newL = Math.min(Math.max(hsl.l + lAdjust, 0), 100);

  return `hsl(${newH}, ${newS}%, ${newL}%, ${opacity ?? 1})`;
}
